import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  isInError = false;
  labIdTooSmall = false;
  onlyNumbericAllowed = false;
  isCallingAPI = false;
  readonly UNABLE_TO_FIND_LABID1 = 'The lab account number entered is not valid. Please check your account number and re-enter.';
  readonly UNABLE_TO_FIND_LABID2 = 'Contact VSPOne Customer Service, if you are unable to access the site.';
  readonly LABID_SHOULD_ONLY_BE_NUMERIC = 'LabID should only be numeric values.';
  readonly LABID_NOT_LONG_ENOUGH = 'LabID not long enough.';

  constructor(private http: HttpClient) {

  }

  onClickSubmit(data): void {
    this.resetTemplateUi();
    if (this.isLabIdOnlyNumeric(data.labID)) {
      if (this.isLabIdViable(data.labID)) {
        this.isCallingAPI = true;
        this.callAPIToGetSAMLUrl(data.labID);
      } else {
        this.labIdTooSmall = true;
      }
    } else {
      this.onlyNumbericAllowed = true;
    }
  }

  callAPIToGetSAMLUrl(labID: string): void {
    this.fetchDataAsPromise(labID)
      .then((data) => {
        this.handleSuccess(data);
      })
      .catch((error) => {
        this.handleError(error);
      });
  }

  // tslint:disable-next-line:ban-types
  fetchDataAsPromise(labID: string): Promise<Object> {
    const apiURL = environment.BASE_URL + labID;
    return this.http
      .get(apiURL)
      .toPromise();
  }

  // tslint:disable-next-line:ban-types
  handleSuccess(data: Object): void {
    this.forwardUserToUPS(JSON.parse(JSON.stringify(data)).samlUrl);
  }

  handleError(error: any): void {
    console.log('Promise rejected with ' + JSON.stringify(error));
    this.isInError = true;
    this.isCallingAPI = false;
  }

  forwardUserToUPS(url: string): void {
    window.location.href = url;
  }

  isLabIdOnlyNumeric(labId: string): boolean {
    return /^\d+$/.test(labId);
  }

  isLabIdViable(labId: string): boolean {
    return labId && labId.length > 2;
  }

  resetTemplateUi(): void {
    this.onlyNumbericAllowed = false;
    this.labIdTooSmall = false;
    this.isInError = false;
  }

}
