<div class="container">
    <div class="logo">
      <img style="height: 90px;" src="https://cdn.vsp.com/dam/jcr:88ea3153-d530-44ba-b88e-4a16c24a14fc" alt="VSP Logo">
    </div>
    <div class="input-field">
        <form #labIdLookup="ngForm" class="form-inline" (ngSubmit) = "onClickSubmit(labIdLookup.value)">
          <div class="form-group mx-sm-3 mb-2">
            <input type="text" id="lab-id-input-field" class="form-control" name="labID" placeholder="Lab ID Number" ngModel>
          </div>
          <button *ngIf="!isCallingAPI" type="submit" value="submit" class="btn btn-primary mb-2">Submit</button>
          <div *ngIf="isCallingAPI" class="loader"></div>
        </form>
      <div *ngIf="isInError">
        <div class="error-message">
          <p [innerHtml]="UNABLE_TO_FIND_LABID1"></p>
          <p [innerHtml]="UNABLE_TO_FIND_LABID2"></p>
        </div>
      </div>
      <div *ngIf="labIdTooSmall">
        <div class="error-message">
          <p [innerHtml]="LABID_NOT_LONG_ENOUGH"></p>
        </div>
      </div>
      <div *ngIf="onlyNumbericAllowed">
        <div class="error-message">
          <p [innerHtml]="LABID_SHOULD_ONLY_BE_NUMERIC"></p>
        </div>
      </div>
    </div>
</div>
